import React from 'react';
import styles from "./FAQItem.module.css";
import Collapse from '@mui/material/Collapse';
import Block from './Block';

const FAQItem = ({ question, answer }) => {
  const [ open, setOpen ] = React.useState(false);
  const toggleCollapse = () => {
    setOpen(!open);
  }

  return (
    <div className={styles.frameParent}>
      <div className={styles.preguntaNmero1Parent} onClick={toggleCollapse}>
        <div className={styles.preguntaNmero1}>{question}</div>
        <img onClick={toggleCollapse} className={open?styles.vectorIconUp:styles.vectorIconDown} alt="" src={"../vector14.svg"} />
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
        <div className={styles.contentAnswer}>
          <div className={styles.loremIpsumDolor}>
            <Block text={answer}  />
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default FAQItem;
