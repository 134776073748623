import { useState, useCallback , Fragment} from "react";
import NFTView1 from "../components/NFTView1";
import PortalPopup from "../components/PortalPopup";
import { NFTCardSaleInfo } from './NFTCard';
import styles from "./NFTs.module.css";
import { Link } from "react-router-dom";

const NFTs = ({ nfts, load ,allNFTs}) => {
  const [isNFTView1Open, setNFTView1Open] = useState(false);
  const [isNFTView2Open, setNFTView2Open] = useState(false);

  const closeNFTView1 = useCallback(() => {
    setNFTView1Open(false);
  }, []);

  const closeNFTView2 = useCallback(() => {
    setNFTView2Open(false);
  }, []);

  return (
    <Fragment>
      <div className={styles.divColeccin}>
        <div className={styles.text}>
          <b className={styles.titulo}>Bienvenido a tu colección</b>
         
        </div>
     
        {
          !load && nfts && nfts.length === 0 ?
          <div className={styles.anyItems} >Aún no tienes NFTs de <b>El clásico de México</b></div>
          :
          <div className={styles.gridContent}>
          {
              !load && nfts && nfts?.map((nft,index)=>{
                  return (
                    <Link to={`/nft?key=${nft.key}&id=${nft.tokenId}`} style={{textDecoration:'none'}}>
                        <NFTCardSaleInfo
                          key={index}
                          nft={nft}
                          allNFTs={allNFTs}
                        />
                    </Link>
                  )
                })
              }
          </div>
        }
        { load &&  <div className={styles.anyItems} >Cargando...</div>}
      </div>
      {isNFTView1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeNFTView1}
        >
          <NFTView1 onClose={closeNFTView1} />
        </PortalPopup>
      )}
      {isNFTView2Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeNFTView2}
        >
          <NFTView1 onClose={closeNFTView2} />
        </PortalPopup>
      )}
    </Fragment>
  );
};

export default NFTs;
