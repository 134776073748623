import styles from "./Attribute.module.css";

const Attribute = ({item}) => {

  const handleGo = (item) => {
    if (String(item.value).substring(0, 8).includes("https://")) {
      window.open(item.value, '_blank');
    }
  }
  const showHashIpfs = (hash) => {
    return hash.substring(0,4)+'...'+hash.substring(hash.length -4, hash.length)
  }
  return (
    <div className={styles.attributes} onClick={()=>handleGo(item)}>
      <div className={styles.background}>{item && item.trait_type && item.trait_type.toUpperCase() }</div>
      <div className={styles.background}></div>
      <div  className={styles.el7Tiene}>
        { item && item.trait_type.includes('image') && item.value &&
          <a className={styles.linkToIpfs} href={item.value}>
            { showHashIpfs(item.value.substring(item.value.length -46, item.value.length)) }
          </a>
        }
        {item && !item.trait_type.includes('image') && item.value && item.value}
      </div>
    </div>
  );
};

export default Attribute;
