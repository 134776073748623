import React, { useContext } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import { loginMetamask, loginWalletConnect } from 'services/blockchain';
import UserContext from 'context/UserContext';


const Wallet = ({ openDrawer, setOpenDrawer }) => {

    const { user,setUser } = useContext(UserContext);

    const handleConnect = async (type) => {
        try {
            let user = null;
            if(type === 1) {
                user = await loginMetamask()
            } else if(type === 2) {
                user = await loginWalletConnect()
            }
            setUser(user)
            setOpenDrawer(false)
        } catch (error) {
            console.log(error);
        }
    }

    const disconnect = async () => {
        try {
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onOpen={() => setOpenDrawer(true)}

        >
            <Box
                sx={{width:{ xs:'100vw',sm:'250px'}}}
            >
                <List
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center'
                    }}
                >
                    <ListItem 
                        sx={{
                            width:'100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <IconButton
                            size="small"
                            edge="start"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={() => setOpenDrawer(false)}
                        >
                        X
                        </IconButton>
                    </ListItem>
                    {!user && 
                        <ListItem button  onClick={() => handleConnect(1)}>
                            <img  style={{ width:'35px'}}alt="" src={'../web3.png'} />
                            &nbsp;
                            <b>
                                Wallet navegador
                            </b>
                        </ListItem>
                    }
                    {!user &&
                        <ListItem button  onClick={() => handleConnect(2)}>
                            <img  style={{ width:'35px'}}alt="" src={'../walletconnect.svg'} />
                            &nbsp;
                            <b>
                                WalletConnect
                            </b>
                        </ListItem>
                    }
                    {user &&
                        <ListItem
                            onClick={ () => { disconnect() }}
                            button sx={{width:'100%',textAlign:'right'}} >
                            <b>
                                <center>Desconectar</center>
                            </b>
                        </ListItem>
                    }
                </List>
           </Box>
        </SwipeableDrawer>
    );
};

Wallet.propTypes = {
    openDrawer: PropTypes.bool,
    setOpenDrawer: PropTypes.func
};

export default Wallet;
