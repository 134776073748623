import { useContext, useEffect, useState } from "react";
import { findByWallet } from "services/user";
import styles from "./HeaderProfile.module.css";
import CollectionContext from "context/CollectionContext";

const HeaderProfile = ({userWallet,myNFts}) => {

  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { collection } = useContext(CollectionContext);

  const shortWallet = () => {
    return userWallet.slice(0, 5) + "..." + userWallet.slice(userWallet.length - 5);
  }

  const walletExplorer = () => {
    return `${collection && collection.explorer && collection.explorer}/address/${userWallet.toLowerCase()}`;
  }

  useEffect(() => {
    if(collection && collection.blockchain){
      findByWallet(process.env.REACT_APP_DOMAIN, collection.blockchain, userWallet).then(result => {
        setUser(result);
        setLoading(false)
      }).catch((error) => console.log(error));
    }
  
  }, []);

  return (
    <div className={styles.profileHeader}>
      <div className={styles.profile}>
        <div className={styles.imagenColeccin}>
          <div className={styles.imagenColeccinChild} />
          <img
            className={styles.imagenColeccinItem}
            alt=""
            src={!isLoading ? user.profile_pic_url : "../rectangle-2108@2x.png"}
          />
        </div>
        <div className={styles.contratoWrapper}>
          <div className={styles.contrato}>
            <div className={styles.contrato1}>
              <div className={styles.estebanDido}>{shortWallet()}</div>
              <div className={styles.iconSharesvg}>
                <a href={walletExplorer()} target="_blank"  rel="noopener noreferrer" style={{marginTop:'10px'}} >
                  <svg width="32" height="26" viewBox="0 0 32 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_36_5794)">
                    <path d="M18.5241 9.20493C18.0508 9.20493 17.6827 9.59931 17.6827 10.0463V12.5703H10.1106V4.9982H12.6347C13.0816 4.9982 13.476 4.63011 13.476 4.15685C13.476 3.70989 13.0816 3.3155 12.6347 3.3155H10.1106C9.1641 3.3155 8.42792 4.07797 8.42792 4.9982V12.5703C8.42792 13.5168 9.1641 14.253 10.1106 14.253H17.6827C18.603 14.253 19.3654 13.5168 19.3654 12.5703V10.0463C19.3654 9.59931 18.971 9.20493 18.5241 9.20493ZM21.2585 0.791466H17.6827C17.3409 0.791466 17.0254 1.0018 16.894 1.31731C16.7625 1.63281 16.8414 2.0009 17.078 2.23753L18.156 3.3155L12.8713 8.6265C12.5295 8.94201 12.5295 9.49414 12.8713 9.80965C13.029 9.9674 13.2394 10.0463 13.476 10.0463C13.6863 10.0463 13.8967 9.9674 14.0544 9.80965L19.3654 4.52494L20.4434 5.60291C20.68 5.83954 21.0481 5.91842 21.3636 5.78696C21.6791 5.6555 21.8895 5.33999 21.8895 4.9982V1.42248C21.8895 1.08068 21.6002 0.791466 21.2585 0.791466Z" fill="#F1F2F2"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_36_5794">
                    <rect width="31" height="14" fill="white" transform="translate(0.346191 0.382812)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.profileHeaderInner}>
        <div className={styles.contentCenter}>
          <div className={styles.items1Wrapper}>
            <div className={styles.items1}>Items ({myNFts && myNFts.length > 0 ? myNFts.length:0})</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderProfile;
