export const handleErrors = (error) => {
    console.log('error in handler', error)
    let formatError = {};

    if(error.response) {
        console.log('response', error.response)
        formatError = {
            title : apiErrors[error.response.status],
            status: error.response.status,
            message: error.response.data.message,
        }
    } else if (error.request) {
        console.log('request', error.request)

        formatError = {
            title : apiErrors[error.request.status],
            status: error.request.status,
            message: error.message,
        }
    } else {
        console.log('error',error, apiErrors[error.code])
        let title = apiErrors[error.code]
        let message = ""
        let status = error.code
        if (title && title.includes('Web3')) {
          message = web3Errors[error.code].message
        }else {
          message = error.message
        }
        formatError = {
            title : title,
            status: status,
            message: message,
        }
    }
    console.log('format',formatError)
    return formatError
}
export const  apiErrors = {
    '500' : 'Error in your request',
    '400' : 'Error in your request',
    '0' : 'Error in your instance',
    '-32700' : 'Web3 Error',
    '4001' : 'Web3 Error',
    '409': 'El NFT ya ha sido vendido',
}
export const web3Errors = {
    '-32700': {
      standard: 'JSON RPC 2.0',
      message:
        'Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.',
    },
    '-32600': {
      standard: 'JSON RPC 2.0',
      message: 'The JSON sent is not a valid Request object.',
    },
    '-32601': {
      standard: 'JSON RPC 2.0',
      message: 'The method does not exist / is not available.',
    },
    '-32602': {
      standard: 'JSON RPC 2.0',
      message: 'Invalid method parameter(s).',
    },
    '-32603': {
      standard: 'JSON RPC 2.0',
      message: 'Internal JSON-RPC error.',
    },
    '-32000': {
      standard: 'EIP-1474',
      message: 'Invalid input.',
    },
    '-32001': {
      standard: 'EIP-1474',
      message: 'Resource not found.',
    },
    '-32002': {
      standard: 'EIP-1474',
      message: 'Resource unavailable.',
    },
    '-32003': {
      standard: 'EIP-1474',
      message: 'Transacción rechazada.',
    },
    '-32004': {
      standard: 'EIP-1474',
      message: 'Method not supported.',
    },
    '-32005': {
      standard: 'EIP-1474',
      message: 'Esta petición excede el limite.',
    },
    '4001': {
      standard: 'EIP-1193',
      message: 'Cancelaste la operación, por favor acepta para continuar.',
    },
    '4100': {
      standard: 'EIP-1193',
      message:
        'No tienes el monto suficiente para completar la transacción.',
    },
    '4200': {
      standard: 'EIP-1193',
      message: 'Este metodo de pago no es soportado por su wallet.',
    },
    '4900': {
      standard: 'EIP-1193',
      message: 'Estas desconectado, por favor conectate.',
    },
    '4901': {
      standard: 'EIP-1193',
      message: 'Estas desconectado, por favor conectate.',
    },
  };