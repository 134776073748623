import React from "react";
import { config } from "services/Sanity/sanity";
import styles from "./SectionPresentation.module.css";

const SectionPresentation = () => {

  const [ info, setInfo ] = React.useState(null);

  const getPresentation = async () =>{
    try {
      const res = await config();
      if(res){
        setInfo(res);
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getPresentation()
  },[]);

  return (
    <div className={styles.header} 
      style={{
        backgroundImage: `url(${info && info.background ? info.background:''})`
      }}
    >
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.playerWrapper}>
            {
              info && info.header &&
              <img
                className={styles.playerImage}
                alt=""
                src={info.header}
                // src="../america_player@2x.png"
              />
            }
          
          </div>
          {/* <div className={styles.playerWrapper}>
            <img
              className={styles.playerImage}
              alt=""
              src="../chivas_player@2x.png"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SectionPresentation;
