import CollectionContext from 'context/CollectionContext';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isSold } from 'services/nfts';
import styles from "./NFTCard.module.css";

const NFTCard = ({ particleImgURL, children }) => {
  const { collection } = useContext(CollectionContext);
  return (
    <div className={styles.nft}>
      <div className={styles.ilustracin}>
        <div className={styles.imgParent} style={{ backgroundImage: `url(${collection.banner})` }}>
          <img className={styles.imgIcon1} alt="" src={particleImgURL} />
        </div>
      </div>
      <div className={styles.ttulo}>
        {children}
      </div>
    </div>
  );
};

export const NFTCardSeeDetails = ({ price, nft , allNFTs , ...props}) => {
  const navigate = useNavigate();
  const handleClickOpen = () => {
    navigate(`/nft?key=${nft.key}&id=${nft.tokenId}`);
  };

  const allAreSaled = () => {
    return allNFTs.every(isSold);
  }

  return (
    <NFTCard particleImgURL={ nft.full_particle_image } {...props}>
      <div className={styles.info}>
        <b className={styles.elClsico1}>{nft.name} ({nft.x_percentage} - {nft.y_percentage})</b>
        <div className={styles.particula1De}>Particula ({nft.x_percentage} - {nft.y_percentage})</div>
        <div className={styles.download1Parent}>
          
          {
            price && !allAreSaled() && 
            <React.Fragment>
              <img
                className={styles.download1Icon}
                alt=""
                src="/download-18@2x.png"
              />
              <div className={styles.div}>{price.eth} / {formatMxnPrice(price.mxn)} mxn</div>
            </React.Fragment>
          }
         
        </div>
        <button className={styles.buyButton} onClick={() => handleClickOpen()}>
          { nft.creator.toUpperCase()  !== nft.owner.toUpperCase() &&
              'Ver detalles'
          }
          { nft.creator.toUpperCase()  === nft.owner.toUpperCase() &&
              ' Adquirir ahora'
          }
        </button>
      </div>
    </NFTCard>
  );
}

const NFTCardSaleInfo = ({ price, nft , allNFTs , ...props}) => {
  return (
    <NFTCard particleImgURL={ nft.full_particle_image } {...props}>
      <div className={styles.info}>
        <b className={styles.elClsico1}>{nft.name} ({nft.x_percentage} - {nft.y_percentage})</b>
        <div className={styles.particula1De}>Particula ({nft.x_percentage} - {nft.y_percentage})</div>
        <div className={styles.download1Parent}>
          
          {
            price && !isSold(nft) && 
            <React.Fragment>
              <img
                className={styles.download1Icon}
                alt=""
                src="/download-18@2x.png"
              />
              <div className={styles.div}>{price.eth} / {formatMxnPrice(price.mxn)} mxn</div>
            </React.Fragment>
          }
         
        </div>
      </div>
    </NFTCard>
  );
}

export const formatMxnPrice = (number) => {
  return number.toLocaleString('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}


export default NFTCard;
export { NFTCardSaleInfo };