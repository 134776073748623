import axios from "axios";

export const findByWallet = async(domain, blockchain, wallet) => {
    return axios.get(process.env.REACT_APP_API+`/user?domain=${domain}&wallet=${wallet}&blockchain=${blockchain}&limit=15&page=0`)
    .then(res=>{
        return res.data[0]
    })
}

export const create =async(domain, blockchain, user) => {
    const data = {
        'domain': domain,
        'blockchain_name': blockchain,
        'homepage':'',
        'username': user.address,
        'email': user.email,
        'about': user.about,
        'twitter':'',
        'facebook':'',
        'profile_pic_url':'',
        'telegram':'',
        'banner_url': '',
        'signature': user.signature,
        'message': user.message,
        'action': 'USER_CREATED'
    }
    return axios.post(process.env.REACT_APP_API+`/user?domain=${domain}`,data,{
        headers:{
            'Content-Type': 'text/plain;charset=utf-8',
        }
    })
}


export const createIfNotExists = async(domain, blockchain, wallet, signature, message) => {
    let user = await findByWallet(domain,blockchain,wallet);
    if(!user.registered) {
        let newUser = {
            address : wallet,
            email : wallet,
            signature : signature,
            message : message
        }
        await create(domain, blockchain, newUser)
    }
    console.log('user', user)
}
