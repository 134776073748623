import React from 'react';

const Block = ({text, styleClass }) => {
    return (
        <span className={styleClass}>
        {
            text &&
            <div>
            {text.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                {line}
                <br />
                </React.Fragment>
            ))}
            </div>
        }
        </span>
    )
}
export default Block;