import HeaderProfile from "../components/HeaderProfile";
import styles from "./ProfileHeader.module.css";

const ProfileHeader = ({...props}) => {
  return (
    <div className={styles.hederprofile}>
      <HeaderProfile {...props} />
    </div>
  );
};

export default ProfileHeader;
