import { useContext } from "react";
import CollectionContext from "../context/CollectionContext";
import styles from "./SectionAbout.module.css";

const SectionAbout = () => {
  const { collection } = useContext(CollectionContext);
  return (collection &&
    <div className={styles.sectionabout}>
      <div className={styles.text}>
        <h3 className={styles.laColeccinOficial}>
          {collection.name }
        </h3>
        <p className={styles.losFanticosDelContainer}>
          {collection.description}
        </p>
      </div>
    </div>
  );
};

export default SectionAbout;
