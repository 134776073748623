import WalletConnectProvider from "@walletconnect/web3-provider";
import mintAndList from 'contracts/PerseaSimpleCollection.json';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import axios from "axios";
import { appInfo } from "./app";

const abis = {
    1 : mintAndList
}
const url = process.env.REACT_APP_API+"blockchain?domain"+process.env.REACT_APP_DOMAIN

export const findAllByDomain = (domain) => {
    return axios.get(url+"?domain="+domain).then(res=>res.data)
}

export const findAllByName = async(domain,name) => {
    const blockchains = await findAllByDomain(domain)
    let newBlockchain = null;
    blockchains.forEach(blockchain => {
        if(blockchain.name.toUpperCase() === name.toUpperCase()) {
            newBlockchain = blockchain
        }
    })
    return newBlockchain
}

export const getWebInstance = (provider, rpc = null) => {
    let web3 = null;
    if (provider) {
        web3 = new Web3(provider);
    } else {
        if(rpc) {
            web3 = new Web3(rpc);
        } else {
            web3 = new Web3(process.env.REACT_APP_RPC);
        }
    }
    return web3
}

export const  sales = async(provider,to, rpc = null, contractType = 1) => {
    console.log(provider,to, rpc,contractType)
    let data = await appInfo()
    console.log('app info', data)
    return data[process.env.REACT_APP_DOMAIN][to]['count_sales']
}

export const  contractSales = async(provider,to, rpc = null, contractType = 1) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(abis[contractType].abi,web3.utils.toChecksumAddress(to));
    let sales = await contract.methods.sales().call();
    console.log('sales', sales);
    return parseInt(sales);
}

export const priceWei = async(provider,to, rpc = null,contractType = 1) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(abis[contractType].abi,web3.utils.toChecksumAddress(to));
    let price = await contract.methods.currentPrice().call()
    console.log('price', price)
    return price
}

export const price = async(provider,to, rpc = null,contractType = 1) => {
    console.log(provider,to, rpc,contractType)
    let data = await appInfo()
    return { eth : parseFloat("2.275"), mxn : parseFloat("141754.34")}
}

export const priceMxn = async(provider,to, rpc = null,contractType = 1) => {
    console.log(provider,to, rpc,contractType)
    let data = await appInfo()
    return parseFloat("141754.34").toFixed(4)
}

export const contractPrice = async(provider,to, rpc = null,contractType = 1) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(abis[contractType].abi,web3.utils.toChecksumAddress(to));
    let price = await contract.methods.currentPrice().call()
    console.log('price', price)
    return  web3.utils.fromWei(""+price,'ether')
}


export const getIfHaveTxt = async (domain, blockchain,from, gas = 'NONE') => {
    const url = `${process.env.REACT_APP_API}/transaction?blockchain=${blockchain}&wallet=${from}&domain=${domain}&gasinfo=${gas}`
    return axios.get(url).then(res=>res.data)
}

export const validateTx = async(domain, blockchain, from,tx)=>{
    const url = `${process.env.REACT_APP_API}/transaction?blockchain=${blockchain}&wallet=${from}&domain=${domain}&gasinfo=NONE&transaction=${tx}`
    return axios.get(url).then(res=>res.data)
}



export const payableMint = async (provider,blockchain, from, to, tokenId, contractType = 1) => {
    console.log(provider,blockchain, from, to, tokenId, contractType)
    let transaction = await getIfHaveTxt(process.env.REACT_APP_DOMAIN,blockchain,from,'FULL')
    if(transaction.gas_info){
        let gasInfo = transaction.gas_info;
        gasInfo['value'] = await priceWei(provider,to)
        let web3 = new Web3(provider);
        let contract = new web3.eth.Contract(
            abis[contractType].abi,
            web3.utils.toChecksumAddress(to)
        );
        await contract.methods.payableMint(1,tokenId).send(gasInfo, async(error, transaction) => {
            console.log('error', error);
            console.log('transaction', transaction);
            if (error) {
                throw new Error("Please contact support with the administrator")
            }
            if(transaction) {
                await validateTx(process.env.REACT_APP_DOMAIN,blockchain,from,transaction)
                let intervalValidateTransaction = setInterval(async() =>{
                    let validation = await getIfHaveTxt(process.env.REACT_APP_DOMAIN,blockchain,from)
                    if (!validation.pending) {
                        if (validation && validation.receipt && validation.receipt.transaction) {
                            clearInterval(intervalValidateTransaction)
                            return transaction
                        } else {
                            clearInterval(intervalValidateTransaction)
                            if (error.message) {
                                throw new Error(error.message)
                            }
                            throw new Error("Please contact support with the administrator")
                        }
                    }
                },6000)
            }

        })

    } else {
        let web3 = new Web3(provider);
        let contract = new web3.eth.Contract(
            abis[contractType].abi,
            web3.utils.toChecksumAddress(to)
        );
        let value = await priceWei(provider,to)
        await contract.methods.payableMint(1,tokenId).send({
            from : from,
            value : value
        }, (error, transaction) => {
            console.log('error', error);
            console.log('transaction', transaction);
        })
    }
};

const web3Connection =async(provider) => {
    const web3 = new Web3(provider)
    const chainID = await web3.eth.getChainId()
    const network = await web3.eth.net.getNetworkType()
    const accounts = await web3.eth.getAccounts()
    const wallet = accounts[0]
    if(chainID && chainID !== process.env.REACT_APP_CHAIN_ID) {
        await changeNetwork(provider,process.env.REACT_APP_CHAIN_ID)
    }
    return {
        provider,
        chainID,
        network,
        wallet
    }
}

export const loginWalletConnect = async() => {
    const provider = new WalletConnectProvider({
        infuraId: 'f15b0b1855494d94bdd6c0fdd5a3cb1a',
    });
    await provider.enable();
    return new Promise((resolve, reject) => {
        try {
            web3Connection(provider).then((user) => {
                resolve(user)
            })
        } catch (error) {
            console.error('walletConnect error::', error);
            reject(error);
        }
    })
}

export const loginMetamask = async() => {
    let provider =  null;
    try {
        provider = await detectEthereumProvider();
    } catch (error) {
        console.log('error in provider',error)
        return Promise.reject('Por favor instala un wallet de navegador')
    }
    if(provider){
        await provider.request({ method: 'eth_requestAccounts' });
        return new Promise((resolve, reject) => {
            if (typeof window.ethereum === 'undefined') {
               throw Error('Por favor instala un wallet de navegador')
            }
            try {
                web3Connection(provider).then((user) => {
                    resolve(user)
                })
            }catch(err){
                localStorage.clear()
               // setMsg("please open your metamask in your browser")
                reject(err)
            }
        });
    } else {
        throw Error('Por favor instala un wallet de navegador')
    }

}


export  const changeNetwork = async (provider,chainId) => {
    return provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `${chainId}` }],
    })
}