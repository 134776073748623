import Tabs from "../components/Tabs";
import ParticleHorizontal from "../components/ParticleHorizontal";
import styles from "./NFTInfoGeneral1.module.css";

const NFTInfoGeneral1 = () => {
  return (
    <div className={styles.info}>
      <Tabs />
      <div className={styles.coleccionable}>
        <div className={styles.descripcin}>
          <div className={styles.descripcin1}>Descripción</div>
          <div className={styles.fueElAoContainer}>
            <p className={styles.fueElAo}>
              1909 fue el año en el que el Club Deportivo Guadalajara alcanzó su
              primer título en la era amateur.
            </p>
            <p className={styles.fueElAo}>&nbsp;</p>
            <p className={styles.coleccinConmemorativaDel}>
              Colección conmemorativa del CXV Aniversario del Rebaño Sagrado.
            </p>
          </div>
        </div>
        <ParticleHorizontal />
        <div className={styles.botonesNft}>
          <div className={styles.share}>
            <div className={styles.v982D3041Wrapper}>
              <img
                className={styles.v982D3041Icon}
                alt=""
                src="/v982d304-11@2x.png"
              />
            </div>
            <div className={styles.verEnOpensea}>Ver en OpenSea</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTInfoGeneral1;
