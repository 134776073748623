import { useMemo } from "react";
import styles from "./Tabs.module.css";

const Tabs = ({
  tabBackgroundColor,
  generalColor,
  tabBackgroundColor1,
  generalColor1,
  tabBackgroundColor2,
  generalColor2,
}) => {
  const tabStyle = useMemo(() => {
    return {
      backgroundColor: tabBackgroundColor,
    };
  }, [tabBackgroundColor]);

  const generalStyle = useMemo(() => {
    return {
      color: generalColor,
    };
  }, [generalColor]);

  const tab1Style = useMemo(() => {
    return {
      backgroundColor: tabBackgroundColor1,
    };
  }, [tabBackgroundColor1]);

  const general1Style = useMemo(() => {
    return {
      color: generalColor1,
    };
  }, [generalColor1]);

  const tab2Style = useMemo(() => {
    return {
      backgroundColor: tabBackgroundColor2,
    };
  }, [tabBackgroundColor2]);

  const general2Style = useMemo(() => {
    return {
      color: generalColor2,
    };
  }, [generalColor2]);

  return (
    <div className={styles.tabs}>
      <div className={styles.tab} style={tabStyle}>
        <div className={styles.general} style={generalStyle}>
          General
        </div>
      </div>
      <div className={styles.tab1} style={tab1Style}>
        <div className={styles.general} style={general1Style}>
          Beneficios
        </div>
      </div>
      <div className={styles.tab1} style={tab2Style}>
        <div className={styles.general} style={general2Style}>
          Metadatos
        </div>
      </div>
    </div>
  );
};

export default Tabs;
