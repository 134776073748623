import React from "react";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import Wallet from "./Wallet";
import UserContext from "context/UserContext";
import { Link } from 'react-router-dom';

const Header = () => {

  const { user } = React.useContext(UserContext);
  
  const [ open, setOpen ] = React.useState(false);
  const navigate = useNavigate();
  
  const handleGo = () => {
    navigate(`/profile?address=${user.wallet}`)
  }
  return (
    <div className={styles.navbar}>
      <Link className={styles.logoWrapper} to={`/home`}>
        <img
          className={styles.logoIcon}
          alt="logo"
          src="/logo.svg"
        />
      </Link>
      <div className={styles.navbarInner}>
        <div className={styles.profileParent}>
          {user && 
            <img
              onClick={handleGo}
              className={styles.profileIcon}
              alt=""
              src="/profile1.svg"
            />
          }
          <img onClick={()=> setOpen(true)} className={styles.walletIcon} alt="" src="/wallet.svg" />
          <Wallet
          openDrawer={open}
          setOpenDrawer={setOpen}
      />
        </div>
      </div>
     
    </div>
  );
};

export default Header;
