import { useState } from "react";
import styles from "./NewsLatter.module.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { NFTCardSaleInfo } from "./NFTCard";
import { BlueButton } from "./Buttons/Button";
import { CheckIcon } from "./DinamicIcons";
const BlockContent = require('@sanity/block-content-to-react');


const NewsLatter = ({ paymentSanity, nft, afterSend }) => {
  const [isAccepted, setIsAccepted] = useState(false)

  const handleAcceptNewsLatter = () => {
    setIsAccepted(!isAccepted);
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email('Correo electrónico inválido')
          .required('Correo obligatorio'),
        phone: Yup.string()
          .matches(/^[0-9]{10}$/, 'Invalid phone number'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          afterSend({...values, acceptedNewsLetter: isAccepted, tokenId: nft.tokenId});
        }, 400);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.newsLatter}>
          <NFTCardSaleInfo nft={nft} />
          <p className={styles.tuNftSe}>
            {/* <b>¡Tu NFT se ha comprado con éxito!</b> */}
            <b>
              {paymentSanity && paymentSanity.successForm && 
              paymentSanity.successForm.title && paymentSanity.successForm.title}
            </b>
          </p>
          <p className={styles.ingresaTuCorreoContainer}>
            <span className={styles.ingresaTuCorreoElectrnico}>

              {
                paymentSanity && paymentSanity.successForm && 
                paymentSanity.successForm.description &&
                <BlockContent  blocks={ paymentSanity.successForm.description }  className={styles.ingresaTuCorreo} />
              }
              {/* <span
                className={styles.ingresaTuCorreo}
              >{`Ingresa tu correo electrónico para unirte al club privado de `}</span>
              <b>{`El Clásico de México `}</b>
              <span className={styles.ingresaTuCorreo}>
                y estar al pendiente de cómo canjear tus beneficios y más noticias.
              </span> */}
            </span>
            <span className={styles.ingresaTuCorreoElectrnico}>
              <span className={styles.ingresaTuCorreo}>&nbsp;</span>
            </span>
            <span className={styles.ingresaTuCorreoElectrnico}>
              <span className={styles.ingresaTuCorreo}>
                Recibirás un correo de confirmación.
              </span>
            </span>
          </p>
          <div className={styles.correoElectrnico}>
            <label htmlFor="email" className={styles.correo}>Correo</label>
            <Field
              className={styles.campo}
              type="email"
              name="email"
              placeholder="example@example.com"
            />
          </div>
          <div className={styles.correoElectrnico}>
            <label htmlFor="phone" className={styles.correo}>Celular</label>
            <Field
              className={styles.campo}
              type="tel"
              placeholder="9911991199"
              name="phone"
            />
          </div>
          <div className={styles.checkbox} onClick={() => handleAcceptNewsLatter()}>
            <CheckIcon
              fill={isAccepted ? "green" : "#79838E"} />
            {
              paymentSanity && paymentSanity.successForm && 
              paymentSanity.successForm.newLetterMessage && paymentSanity.successForm.newLetterMessage
            }
          </div>
          <BlueButton type="submit" text="Enviar e ir a mi coleción"  disabled={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default NewsLatter;
