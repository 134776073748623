import styles from "./Button.module.css";

const Button = ({className="", text, icon, handleClick, disabled, ...props}) => {
  if (disabled) {
    className = styles.disabled
  }
  const handleOnClick = () => {
    if (disabled) return;
    return handleClick();
  }
  return (
    <button className={`${styles.botontdc} ${className}`} onClick={() => handleOnClick()} {...props}>
      {icon && <img className={styles.icon} alt="" src={icon} />}
      <b className={styles.metamask}>&nbsp;&nbsp;{text}</b>
    </button>
  );
};

const WhiteButton = ({className="",  ...props}) => {
  return (
    <Button className={`${styles.white} ${className}`} {...props} />
  );
}

const BlueButton = ({...props}) => {
  return (
    <Button className={styles.blue} {...props} />
  );
}

export { WhiteButton, BlueButton }
export default Button;
