//import WalletConnectProvider from "@walletconnect/web3-provider";
//import mintAndList from 'contracts/PerseaSimpleCollection.json';
/* import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3'; */
import axios from "axios";

/* const abis = {
    1 : mintAndList
} */
const url = process.env.REACT_APP_API

export const  appInfo = () => {
    return axios.get(url).then(res=>res.data)
}

export const  createAppInfo = (data) => {
    return axios.post(url, data).then(res=>res.data)
}