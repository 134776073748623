

const Error = ({ status, title,message }) => {
    return (
        <div style={{color : 'red',textAlign: 'center', padding:'10px 0px 12px 0px', margin:'auto'}}>
            <h2>{status}</h2>
            <h3>{title}</h3>
            {/* <FontAwesomeIcon icon={faTriangleExclamation} size="2x" color="red" /> */}
            <h4>{message}</h4>
{/*             <b>Por favor comunicate a  { process.env.REACT_APP_SUPPORT_EMAIL}, si tienes algún problema</b> */}
        </div>
    )
}

export default Error;
