import { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { WhiteButton, BlueButton } from "./Buttons/Button";
import ButtonUnfilled from "./Buttons/ButtonUnfilled";
import styles from "./ModalBuy.module.css";
import { NFTCardSaleInfo } from './NFTCard';
import { CheckIcon } from './DinamicIcons';
import NewsLatter from './NewsLatter';
import UserContext from '../context/UserContext';
import { loginMetamask, loginWalletConnect, payableMint } from '../services/blockchain';
import { create as createUser } from '../services/user';
import CollectionContext from 'context/CollectionContext';
import { handleErrors } from 'services/messages';
import Error from './Error';
import NFTSContext from 'context/NFTSContext';
import { addSalesCountToEach, findAllByProjectKey, isSold } from 'services/nfts';
import Process from '../Process';
const BlockContent = require('@sanity/block-content-to-react');

export const PHASES = {
  selectPaymentMethod: 1,
  buying: 2,
  fillData: 3,
  finish: 4,
}

const ModalBuy = ({
  onClose,
  selectedValue,
  open,
  setOpen,
  nft,
  handleWinterCheckout,
  handleFinishTransaction,
  currentPhase,
  handlePaymentError,
  handleFinalPhase,
  currentPrice,
  allNFTs,
  paymentSanity
}) => {

  const { user , setUser } = useContext(UserContext);
  const { collection } = useContext(CollectionContext);
  const [msg, setMsg] = useState({})
  const [error, setError] = useState(null)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { nfts, setNFTS } = useContext(NFTSContext);

  const handleBuyWithWallet = async() => {
    try {
      await checkAvailability();
      setMsg({ process : {
        title: 'Esperando firma',
        message: 'Asegúrate que la ventana de firma de tu wallet esté abierta'
      }})
      await payableMint(user.provider, nft.blockchain,user.wallet, process.env.REACT_APP_COLLECTION,nft.tokenId)
      handleFinishTransaction()
      setMsg({})
    } catch (error) {
      showError(error);
    }
  }

  const handleBuyWithCard = async () => {
    try {
      await checkAvailability();
      handleWinterCheckout();
    } catch (error) {
      showError(error);
    }
  }

  const showError = (error) => {
    console.log(error);
    handlePaymentError(handleErrors(error));
    setError(handleErrors(error));
    setMsg({});
  }

  const checkAvailability = async () => {
    setMsg({ process : {
      title: 'Revisando disponiblidad',
      message: 'Tenemos que asegurarnos que el NFT que intentas comprar sigue disponible'
    }})
    await updateNFTSContext();
    nft = nfts.filter(updatedNFT => updatedNFT.key.toUpperCase() === nft.key.toUpperCase() &&  updatedNFT.tokenId === nft.tokenId)[0];
    console.log('nfts found ',nft)
    if (isSold(nft)) {
      throw {
        message: "¡Mil disculpas!, el NFT ya ha sido comprado por alguien más",
        code: "409"
      };
    }
    setMsg({});
  }

  const updateNFTSContext = async () => {
    const updatedNFTS = await findAllByProjectKey(collection.key);
    const NFTSWithSalesCount = await addSalesCountToEach(updatedNFTS);
    setNFTS(NFTSWithSalesCount);
  }

  const handleConnect = async(type) => {
    try {
      setMsg({ process : {
        title: 'Esperando firma',
        message: 'Asegúrate que la ventana de firma de tu wallet esté abierta'
      }})
      let user = null;
      if(type === 1) {
        user = await loginMetamask()
      } else if(type === 2) {
        user = await loginWalletConnect()
      }
      setUser(user)
      setMsg({})
    } catch (error) {
      if (error.message === "User closed modal") {
        error.code = 4001;
      }
      setError(handleErrors(error))
      setMsg({})
    }
    console.log('Connect', user)
  }

  const [isTermsConditionsAccepted, setIsTermsConditionsAccepted] = useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  }

  const handleAfterSendNewsLatterSubscription = async (values) => {
    setOpen(true);
    try {
      setMsg({ process : {
        title: 'Actualizando datos',
        message: 'Por favor no cierres esta ventana, guardando tu información'
      }})
      await createUser(process.env.REACT_APP_DOMAIN, collection.blockchain, {
        address: values.email,
        email: values.email,
        about: JSON.stringify({
          phone: values.phone,
          accepted_newsletter: values.acceptedNewsLetter,
          token_id: values.tokenId,
          collection_key: collection.key
        }),
        signature: "signature",
        message: "message",
      });
      setOpen(false);
      handleFinalPhase();
      setMsg({})
    } catch (error) {
      setError(handleErrors(error))
      setMsg({})
    }
  }

  const handleAcceptTermsConditions = () => {
    setIsTermsConditionsAccepted(!isTermsConditionsAccepted);
  }

  const handleFinish = () => {
    window.location.reload();
  }

  let modalContent = (
    <>
      {currentPrice && nft && allNFTs &&
        <NFTCardSaleInfo
          nft={nft}
          price={currentPrice}
          allNFTs={allNFTs}
        />
      }
      <div className={styles.procederAlPago}>
        {/* Proceder al pago */}
        {paymentSanity && paymentSanity.paymentForm && 
        paymentSanity.paymentForm.disclaimer && paymentSanity.paymentForm.disclaimer}
      </div>
      <div className={styles.checkbox} onClick={() => handleAcceptTermsConditions()}>
        <CheckIcon
          fill={isTermsConditionsAccepted ? "green" : "#79838E"}/>
        &nbsp;Aceptar&nbsp;<a className={styles.termsConditionsLink} href='/terms-and-conditions'>términos y condiciones.</a>
      </div>
      <div className={styles.botonesMtodoDePago}>
        {!user && !msg.process && !error &&
          <>
            <WhiteButton
            text="Wallet de navegador"
            icon="../web3.png"
            disabled={!isTermsConditionsAccepted}
            handleClick={() => handleConnect(1) }/>
            <WhiteButton
            text="Wallet Connect"
            icon="../walletconnect.svg"
            disabled={!isTermsConditionsAccepted}
            handleClick={() => handleConnect(2) }/>
          </>
        }
        { user && !msg.process && !error &&
          <WhiteButton
            text="Comprar con wallet"
            icon="../web3.png"
            disabled={!isTermsConditionsAccepted}
            handleClick={() => handleBuyWithWallet() }/>
        }
        {!msg.process && !error &&
          <>
            <BlueButton
              text="Comprar con tarjeta de crédito/débito"
              disabled={!isTermsConditionsAccepted}
              handleClick={() => handleBuyWithCard() }/>
            <ButtonUnfilled text="Cancelar" handleClick={() => setOpen(false)} />
          </>
        }
        {msg.process && !error &&
          <Process
          title={msg.process.title} 
          message={msg.process.message} />
        }
        { error &&
          <>
            <Error
              message = {error.message}
              error={error.status}
              title = { error.title}/>
            <BlueButton
              text="Cerrar"
              handleClick={() => handleFinish()} />
          </>
        }
      </div>
    </>
  );

  if (currentPhase === PHASES.fillData) {
    if(!error) {
      modalContent = <NewsLatter 
      paymentSanity={paymentSanity} 
      nft={nft} afterSend={(values) => handleAfterSendNewsLatterSubscription(values)}/>
    } else {
      modalContent = (
        <>
          <Error
            message = {error.message}
            error={error.status}
            title = { error.title}/>
          <BlueButton
            text="Cerrar"
            handleClick={() => handleFinish()} />
        </>
      );
    }
  }

  if (currentPhase === PHASES.finish) {
    modalContent = (
      <>
        {
          paymentSanity && paymentSanity.successMessage && 
          paymentSanity.successMessage.description &&
          <div className={styles.thanksMessage}>
            <BlockContent  blocks={ paymentSanity.successMessage.description } />
          </div>
        }
        <BlueButton
          text="Cerrar"
          handleClick={() => handleFinish()} />
      </>
    );
  }

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
      <div className={styles.blackscreen}>
        {modalContent}
      </div>
    </Dialog>
  );
};

export default ModalBuy;