import styles from "./NFTView.module.css";
import NFTInfoGeneral1 from "./NFTInfoGeneral1";
const NFTView = ({ onClose }) => {
  return (
    <div className={styles.nftView}>
    
      <div className={styles.nft}>
        <div className={styles.nft1}>
          <div className={styles.ilustracinCompleta}>
            <div className={styles.imgWrapper}>
              <img className={styles.imgIcon} alt="" src="../img8@2x.png" />
            </div>
            <img
              className={styles.ilustracinCompletaChild}
              alt=""
              src="../img8@2x.png"
            />
          </div>
        </div>
        <div className={styles.ttulo}>
          <b className={styles.elClsico1}>El Clásico #1</b>
          <div className={styles.enTuPosesin}>En tu posesión.</div>
        </div>
      </div>
      <div className={styles.info}>
        <NFTInfoGeneral1/>
      </div>
      <div className={styles.nftViewChild} />
        <div className={styles.btnBuy}>
        Comprar
        </div>
        <div className={styles.btnComeBack}>
        Volver a la tienda
        </div>
    </div>
  );
};

export default NFTView;
