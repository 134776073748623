import React from 'react';
import styles from "./Footer.module.css";
import { footer } from '../services/Sanity/sanity';


const FooterItem = ({item,pos}) => {
  const handleOpenUrl = (url) => () =>{
    window.open(url, 'url');
  }
  if(item && item.isExternal && item.image && item.url){
    return (
      <img onClick={handleOpenUrl(item.url)} className={pos === 0 ? styles.chivasBig : styles.logoChivasIcon} alt="" src={item.image} />
    )
  }
  if(item && !item.isExternal && item.image && item.url){
    return (
      <img className={styles.logoChivasIcon} alt="" src={item.image} />
    )
  }
  return null;

}

const Footer = () => {

  const [ images, setImages ] = React.useState([]);
  const getFooter = async () =>{
    try {
      const res = await footer();
      if(res && res.length > 0){
        setImages(res);
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getFooter()
  },[]);

  return (
    <div className={styles.newfooterdesktop}>
      <footer className={styles.poweredByParent}>
        <div style={{display: "flex", alignItems: "center"}}>
          <a  href='https://www.avocadoblock.com/' target={'_blank'} rel="noopener noreferrer" style={{textDecoration:'none', marginRight: 15}}>
            <img alt="" src={"../powered-by1.svg"} className={styles.poweredByIcon}  />
          </a>
          <a  href='https://persea.avocadoblock.com/' target={'_blank'} rel="noopener noreferrer" style={{textDecoration:'none'}}>
            <img alt="" src="../persea_logo.png" className={styles.perseaIcon}  />
          </a>
        </div>
        <div className={styles.logos}>
            {
              images?.map((item,index)=>{
                return (
                  <FooterItem key={index} item={item} pos={index}/>
                )
              })
            }
        </div>
      </footer>
    </div>
  );
};

export default Footer;
