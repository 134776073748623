import React, { useState, useContext } from "react";
import styles from "./Store.module.css";
import NFTSContext from "../context/NFTSContext";
import { gallery } from "services/Sanity/sanity";
import CollectionContext from "context/CollectionContext";
import { NFTCardSeeDetails } from "components/NFTCard/NFTCard";
import PriceContext from "context/PriceContext";


const BlockContent = require('@sanity/block-content-to-react')

const Store = ({onClose}) => {
  const { nfts } = useContext(NFTSContext);
  const { collection } = useContext(CollectionContext);
  const [ info, setInfo ] = useState(null)
  const { currentPrice } = useContext(PriceContext);

  const getGallery = async () =>{
    try {
      const res = await gallery();
      console.log(res)
      setInfo(res)
    } catch (error) {
      console.error(error);
    }
  }
  React.useEffect(() => {
    getGallery();
  },[]);

  const galleryItems = nfts.map((currentNFT, index) => {
    return <NFTCardSeeDetails key={index} nft={ currentNFT } allNFTs={ nfts } price={ currentPrice } />
  });

  return (info &&
    <div className={styles.store}>
      
          <button style={{
          cursor: 'pointer',
          zIndex: 99999, float: 'right',
          fontSize:'2rem', position:'fixed',
          color: '#fff',
          border: '0px', background: 'transparent',
          right: '30px'}} onClick={ () => { onClose(false)}}>X</button>
      <div className={styles.collectioncontainer}>
      <img className={styles.bgIcon} alt="" src={collection.banner}/>
        <div className={styles.text}>
          <h2 className={styles.titulo}>{info.title }</h2>
          <div className={styles.loremIpsumDolor} >
            <BlockContent   blocks={ info.description }/>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.itemsContainer}>
            { galleryItems }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
