import { createClient } from '@sanity/client';

const client = createClient({
        projectId: 'h6jz13eh',
        dataset: 'production',
        apiVersion: '2022-12-04',
        useCdn: true
    });


export const faqs = async() => {
    return client.fetch(`*[_type == "faqs"] {
        title,
        description,
        faqs [] {
            faq_title,
            faq_description
        }
    }`).then((data) => {
        let faqs = []
        for (let index = 0; index <  data.length; index++) {
            const element = data[index];
           let faq = {
                description: element.description,
                faqs : [],
                title: element.title
            }
            for (let index = 0; index < element['faqs'].length; index++) {
                const new_faq = element['faqs'][index];
                faq['faqs'].push({
                    description : toPlainText(new_faq.faq_description),
                    title : new_faq.faq_title
                })
            }
           faqs.push(faq)
        }
        return faqs
    }).catch((error) => { throw error})
}

export const footer = async() => {
    return client.fetch(`*[_type == "footer"] {
        icons [] {
            external_link,
            href,
            name,
            new_icon {
                asset->{
                    _id,
                    url
                } ,
            }
        }
    }`).then((data) => {
        let footer = []
        for (let index = 0; index <  data[0]['icons'].length; index++) {
            const element = data[0]['icons'][index];
            footer.push({
                isExternal : element.external_link,
                url : element.href,
                name : element.name,
                image : element['new_icon']['asset']['url']
            })
        }
        return footer
    }).catch((error) => { throw error})
}

export const about = async() => {
    return client.fetch(`*[_type == "aboutArt"] {
        title,
        description,
        buy_disclaimer
    }`).then((data) => {
        return {
            title : data[0].title,
            description : toPlainText(data[0].description),
            disclaimer : toPlainText(data[0].buy_disclaimer)
        }
    }).catch((error) => { throw error})
}

export const benefits = async() => {
    return client.fetch(`*[_type == "benefits"] {
        title,
        description,
        disclaimer,
        benefits [] {
            benefitTitle,
            benefitSubtitle
        }
    }`).then((data) => {
        let benefits = []
        for (let index = 0; index <  data.length; index++) {
            const element = data[index];
            let benefit = {
                title : element.title,
                description: element.description,
                disclaimer : element.disclaimer,
                benefits : []
            }
            for (let i = 0; i < element['benefits'].length; i++) {
                const benefitItem = element['benefits'][i];
                benefit['benefits'].push({
                    title : benefitItem.benefitTitle,
                    description : benefitItem.benefitSubtitle
                })
            }
            benefits.push(benefit)
        }
        return benefits[0]
    }).catch((error) => { throw error})
}


export const artist = async() => {
    return client.fetch(`*[_type == "artist"] {
        name,
        faq_description,
        making_description,
        video,
        profileImage,
        socials [] {
            new_icon {
                asset->{
                    _id,
                    url
                } ,
            },
            name,
            href,
            show_icon
        }
    }`).then((data) => {
        console.log('data',data)
        let artists = []
        for (let index = 0; index <  data.length; index++) {
            const element = data[index];
            let artist = {
                name : element.name,
                description : toPlainText(element.faq_description),
                making_of : element.making_description,
                image : element.profileImage,
                video : element.video,
                socials : []
            }
            for (let i = 0; i < element['socials'].length; i++) {
                const social = element['socials'][i];
                artist['socials'].push({
                    href : social.href,
                    image : social['new_icon']['asset']['url'],
                    name :social['name']
                })
            }
            artists.push(artist)
        }
        return artists
    }).catch((error) => { throw error})
}

export const toPlainText = (blocks = []) => {
    return blocks
      // loop through each block
      .map(block => {
        // if it's not a text block with children, 
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return ''
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map(child => child.text).join('')
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n')
  }

export const donation = async() => {
    return client.fetch(`*[_type == "donation"] {
        title,
        description,
        donations [] {
            donationTitle,
            href,
            donationDescription,
            donationImage {
                asset->{
                    _id,
                    url
                } ,
            },
        }
    }`).then((data) => {
        let donations = [];
        for (let index = 0; index <  data.length; index++) {
            const element = data[index];
            let donation = {
                title : element.title,
                description : element.description,
                donations : []
            }
            for (let i = 0; i < element['donations'].length; i++) {
                const donationSection = element['donations'][i];
                donation['donations'].push({
                    title : donationSection.donationTitle,
                    description : donationSection.donationDescription,
                    href : donationSection.href,
                    image : donationSection['donationImage']['asset']['url'],
                })
            }
            donations.push(donation)
        }
        return donations
    }).catch((error) => { throw error})
}

export const teams = async() => {
    return client.fetch(`*[_type == "teams"] {
        teams [] {
            name,
            brand_image {
                asset->{
                    _id,
                    url
                } ,
            },
            socials [] {
                socialIcon {
                    asset->{
                        _id,
                        url
                    } ,
                },
                href
            }
        }
    }`).then((data) => {
        let teams = []
        for (let index = 0; index <  data[0]['teams'].length; index++) {
            const element = data[0]['teams'][index];
            let team = {
                brand : element['brand_image']['asset']['url'],
                socials : []
            }
            for (let index = 0; index < element['socials'].length; index++) {
                const social = element['socials'][index];
                team['socials'].push({
                    href : social.href,
                    image : social['socialIcon']['asset']['url']
                })
            }
            teams.push(team)
        }
        return teams
    }).catch((error) => { throw error})
}

export const gallery = async() => {
    return client.fetch(`*[_type == "gallery"] {
        name,
        description
    }`).then((data) => {
        return {
            title : data[0].name,
            description : data[0].description
        }
    }).catch((error) => { throw error})
}

export const payment = async() => {
    return client.fetch(`*[_type == "payment"] {
        credit_card_button,
        proceed_to_payment_disclaimer,
        process_to_payment_disclaimer,
        success_payment_message_title,
        success_payment_message_description,
        news_letter_message,
        success_email_sent_message
    }`).then((data) => {
        console.log('data', data);
        return {
            buttonCreditCardText : data[0]['credit_card_button'],
            paymentForm : {
                process : data[0]['process_to_payment_disclaimer'],
                disclaimer : data[0]['proceed_to_payment_disclaimer']
            },
            successForm : {
                title : data[0]['success_payment_message_title'],
                description : data[0]['success_payment_message_description'],
                newLetterMessage : data[0]['news_letter_message']
            },
            successMessage : {
                description : data[0]['success_email_sent_message']
            }
        }
    }).catch((error) => { throw error})
}

export const config = async() => {
    return client.fetch(`*[_type == "config"] {
        image {
            asset->{
                _id,
                url
            } ,
        },
        background {
            asset->{
                _id,
                url
            } ,
        },
        terms_and_conditions_title,
        terms_and_conditions
    }`).then((data) => {
        console.log('data', data);
        return {
            header : data[0]['image']['asset']['url'],
            background : data[0]['background']['asset']['url'],
            termsAndConditions : {
                title : data[0]['terms_and_conditions_title'],
                description : toPlainText(data[0]['terms_and_conditions'])
            }
        }
    }).catch((error) => { throw error})
}

