import styles from "./CustomButton.module.css";

const CustomButton = ({handleGo,showBuy }) => {
  return (
    <div onClick={ () => {handleGo()}} className={styles.boton}>
      <b className={styles.adquirirAhora}>{showBuy  ? 'Ver galeria' : 'Apoya a la causa'}</b>
    </div>
  );
};

export default CustomButton;
