import styles from "./ParticleVertical.module.css";

const ParticleVertical = ({nft}) => {
  return (
    <div className={styles.attributes}>
      {
        nft && nft.particle_image && 
        <img className={styles.imgIcon} alt="" src={nft.particle_image} />
      }
      {
        nft && nft.particle_description && 
        <div className={styles.partculaParent}>
          <div className={styles.partcula}>Partícula</div>
          <div className={styles.el10Tiene}>{nft.particle_description}</div>
        </div>
      }
    </div>
  );
};

export default ParticleVertical;
