import styles from "./NFTInfoGeneral.module.css";

const NFTInfoGeneral = ({nft}) => {
  return (
    <div className={styles.coleccionable}>
      <div className={styles.descripcin}>
        <div className={styles.descripcin1}>Descripción</div>
        <div className={styles.fueElAoContainer}>
          <p className={styles.fueElAo}>
            {
              nft && nft.description && nft.description
            }
          </p>
{/*           <p className={styles.fueElAo}>&nbsp;</p>
          <p className={styles.coleccinConmemorativaDel}>
            Colección conmemorativa del CXV Aniversario del Rebaño Sagrado.
          </p> */}
        </div>
      </div>
      <div className={styles.attributes}>
        {
          nft && nft.particle_image && 
          <img className={styles.imgIcon} alt="" src={nft.particle_image} />
        }
        {
          nft && nft.particle_description && 
          <div className={styles.partculaParent}>
            <div className={styles.partcula}>Partícula</div>
            <div className={styles.el10Tiene}>{nft.particle_description}</div>
          </div>
        }
      </div>
    </div>
  );
};

export default NFTInfoGeneral;
