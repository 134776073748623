import React, { useEffect } from 'react';
import CollectionContext from "context/CollectionContext";
import { appInfo, createAppInfo } from "services/app";
import { contractPrice, contractSales } from "services/blockchain";
import axios from "axios";

const Sync = () => {
    const { collection } = React.useContext(CollectionContext);
    useEffect(() => {
      if(collection) {
        console.log("Incia el intervalo")
        let count_reload = 0;
        let reload = false;
        let consult_api = 0;
        let price_mxn = 32661
        setInterval(async() => {
          console.log('collection.rpc', collection.rpc)
          let res = 0;
          let count = 0;
          try {
            res = await contractPrice(null, collection.key, collection.rpc);
            count = await contractSales(null, collection.key, collection.rpc);
          } catch (error) {
            console.log('error', error)
          }
            
         console.log('res', res)
         console.log('count', count)
         let data = {
         }
         data[process.env.REACT_APP_DOMAIN] =  {}
         if(count_reload < 6 && count >= 10) {
            count_reload++;
            reload = true;
         } else {
            reload = false;
         }
         if(consult_api === 6) {
          consult_api = 0;
          let mxn = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=mxn")
          if(mxn) {
            price_mxn = mxn.mxn
          }
         }
         data[process.env.REACT_APP_DOMAIN][collection.key] = {
           price : res,
           price_mxn : res * price_mxn,
           count_sales : count,
           reload : reload
         }
         await createAppInfo(data)
         console.log('data updated', data)
         appInfo().then((data) => {
           console.log('data of domain',data)
         })
        },10000)
      }
    },[collection])
}

export default Sync