import axios from "axios";
import { sales } from "./blockchain";

const url = process.env.REACT_APP_API+"nft?domain="+process.env.REACT_APP_DOMAIN

export const findAllByProjectKey = async(key) => {
    return axios.get(url+"&address="+key+"&order=created&limit=30&page=0", {
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_X_API_KEY    
        }
    }).then(res=>{
       let nfts = []
       res.data.forEach((nft) => {
            nfts.push(formatNFT(nft));
       })
       let coordinate_y1 = []
       let coordinate_y2 = []

       nfts.sort((a, b) => {
        const [aAge, aSalary] = [a.coordinate_x, a.coordinate_y].map(Number);
        const [bAge, bSalary] = [b.coordinate_x, b.coordinate_y].map(Number);
        return aAge - bAge || aSalary - bSalary;
      });
       let x_1 = 0;
       let x_2 = 0;
       for (const [index, nft] of nfts.entries()) {
        if( index % 2 === 0) {
            nft.x_percentage = x_1;
            nft.y_percentage = 0 ;
            x_1+=20
            coordinate_y2.push(nft)
        } else {
            nft.x_percentage = x_2;
            nft.y_percentage = 50;
            x_2+=20
            coordinate_y1.push(nft)
        }
       }

        return coordinate_y2.concat(coordinate_y1)
    })
}

export const findByUserAddress = async (userWallet) => {
    return axios.get(url+"&wallet="+userWallet+"&order=created&limit=30&page=0", {
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_X_API_KEY    
        }
    }).then(res=>{
       let nfts = []
       res.data.forEach((nft) => {
            nfts.push(formatNFT(nft));
       })
       return nfts;
    })
}

const formatNFT = (nft) => {
    console.log('nfts ::',nft)
    let particle_image = nft.metadata.json_data.attributes.find((x) =>x.trait_type === "particle image")
    let full_particle_image = nft.metadata.json_data.attributes.find((x) =>x.trait_type === "full particle image")
    let coordinate_x = nft.metadata.json_data.attributes.find((x) =>x.trait_type === "x")
    let coordinate_y = nft.metadata.json_data.attributes.find((x) =>x.trait_type === "y")
    let particle_description = "La particula se encuentra en el eje x a "+coordinate_x.value+" y el eje y "+coordinate_y.value

    return {
        name : nft.metadata.json_data.name,
        thumb_url : nft.metadata.image_url,
        key : nft.collection_key,
        tokenId : nft.token_id,
        domain : nft.collection.domain,
        blockchain : nft.collection.blockchain.blockchain_name,
        testnet : nft.collection.blockchain.testnet,
        creator: nft.creator,
        owner: nft.owner,
        symbol : nft.collection.blockchain.symbol,
        explorer : nft.collection.blockchain.explorer,
        particle_image : particle_image.value.replace('https://ipfs.io/ipfs/', 'https://images.persea.app/extra_files/'),
        full_particle_image : full_particle_image.value.replace('https://ipfs.io/ipfs/', 'https://images.persea.app/extra_files/'),
        coordinate_x : coordinate_x.value,
        coordinate_y : coordinate_y.value,
        description : nft.metadata.json_data.description,
        attributes : nft.metadata.json_data.attributes,
        particle_description : particle_description
    }

}

export const isSold = (nft) => {
  return nft.creator.toUpperCase() !== nft.owner.toUpperCase()
}

export const addSalesCountToEach = async (nfts) => {
    const res = await sales(null,process.env.REACT_APP_COLLECTION,null);
    for (let index = 0; index < nfts.length; index++) {
        nfts[index].sales = res;
    }
    return nfts;
}