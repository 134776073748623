import styles from "./ParticleHorizontal.module.css";

const ParticleHorizontal = () => {
  return (
    <div className={styles.attributes}>
      <img className={styles.imgIcon} alt="" src="../img11@2x.png" />
      <div className={styles.partculaParent}>
        <div className={styles.partcula}>Partícula</div>
        <div className={styles.el10Tiene}>El 10% tiene este atributo</div>
      </div>
    </div>
  );
};

export default ParticleHorizontal;
