import styles from "./ButtonUnfilled.module.css";
import Button from "./Button";

const ButtonUnfilled = ({...props}) => {
  return (
    <Button className={styles.botoninitcancelar} {...props}/>
  );
};

export default ButtonUnfilled;
