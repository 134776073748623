import { useState, useCallback , useEffect ,useContext} from "react";
import NFTView1 from "../components/NFTView1";
import PortalPopup from "../components/PortalPopup";
import Header from "../components/Header";
import ProfileHeader from "../components/ProfileHeader";
import NFTView from "../components/NFTView";
import NFTs from "../components/NFTs";
import FAQs from "../components/FAQs";
import styles from "./PageProfile.module.css";
import Footer from "../components/Footer";
import { useLocation } from 'react-router';
import { findByUserAddress } from "services/nfts";
import NFTSContext from "context/NFTSContext";

const PageProfile = () => {

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const address = query.get('address'); 
  const [ isNFTView1Open, setNFTView1Open ] = useState(false);
  const [ isNFTView2Open, setNFTView2Open ] = useState(false);
  const [ isNFTViewOpen, setNFTViewOpen ] = useState(false);
  const [ myNFts, setMyNfts ] = useState([]);
  const [ load, setLoad ] = useState(true);
  const { nfts } = useContext(NFTSContext)

  const closeNFTView1 = useCallback(() => {
    setNFTView1Open(false);
  }, []);

  const closeNFTView2 = useCallback(() => {
    setNFTView2Open(false);
  }, []);

  const closeNFTView = useCallback(() => {
    setNFTViewOpen(false);
  }, []);
  
  useEffect(() => {
    findByUserAddress(address.toUpperCase()).then(result => {
      setMyNfts(result);
      setLoad(false);
    });
  }, [address]);

  return (
    <>
      <div className={styles.pageprofile}>
        <Header />
        <div className={styles.bgProfile}>
          <div className={styles.profileMobile}>
            <ProfileHeader userWallet={address} myNFts={myNFts}/>
            <NFTs nfts={myNFts} load={load} allNFTs={nfts}/>
          </div>
        </div>
        <div className={styles.sections}>
          <FAQs />
        </div>
      </div>
   
      <Footer/>
      {isNFTView1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeNFTView1}
        >
          <NFTView1 onClose={closeNFTView1} />
        </PortalPopup>
      )}
      {isNFTView2Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeNFTView2}
        >
          <NFTView1 onClose={closeNFTView2} />
        </PortalPopup>
      )}
      {isNFTViewOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeNFTView}
        >
          <NFTView onClose={closeNFTView} />
        </PortalPopup>
      )}
    </>
  );
};

export default PageProfile;
