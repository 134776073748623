import React from 'react';
import FAQItem from "./FAQItem";
import styles from "./FAQs.module.css";
import { faqs } from '../services/Sanity/sanity';
const FAQs = () => {

  const [ description, setDescription ] = React.useState();
  const [ faqsArray, setFaqsArray ] = React.useState([]);
  const [ title, setTitle ] = React.useState('');

  const getFAQs = async () =>{
    try {
      const res = await faqs();
      if(res && res.length > 0 && res[0].description){
        setDescription(res[0].description);
        setTitle(res[0].title)
        if(res[0].faqs && res[0].faqs.length > 0){
          setFaqsArray(res[0].faqs);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }


  React.useEffect(() => {
    getFAQs()
  },[]);


  return (
    <div className={styles.contentBody}>
      <div className={styles.faqdefault}>
        <div className={styles.text}>
          <b className={styles.titulo}>{title}</b>
          <div
            className={styles.loremIpsumDolor}
          >{description}</div>
        </div>
        <div className={styles.instanceParent}>
          {
            faqsArray?.map((item,index)=>{
              return(
                item && item.title && item.description &&
                <FAQItem 
                  key={index}
                  question={item.title}
                  answer={item.description}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default FAQs;
