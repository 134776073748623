import HourglassTopIcon from '@mui/icons-material/HourglassTop';

const Process = ({ title,message }) => {
    return (
        <div style={{textAlign: 'center', padding:'10px 0px 12px 0px', margin:'auto', color: 'var(--black5)'}}>
            <h3>{title}</h3>
            <HourglassTopIcon color="action"/>
            <h4>{message}</h4>
        </div>
    )
}

export default Process;