import React, { useContext } from "react";
import { WinterCheckout } from '@usewinter/checkout';
import CollectionContext from "context/CollectionContext";
import NFTInfoGeneral from "../components/NFTInfoGeneral";
import Attribute from "./Attribute";
import styles from "./NFTView1.module.css";
import ParticleVertical from "./ParticleVertical";
import ModalBuy, { PHASES } from "../components/ModalBuy";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import { benefits } from '../services/Sanity/sanity';
import Error from "./Error";
import { NFTCardSaleInfo } from "./NFTCard";
import PriceContext from "context/PriceContext";
import CarouselCustomViewNFT from "./Carousel/CarouselCustomViewNFT";
const BlockContent = require('@sanity/block-content-to-react');

const handleResize = (size) => {
  if(size < 600){
      return "mobile";
  }else
  if(size >= 600 && size < 900){
      return "tablet";
  }else
  if(size >= 900 && size < 1440){
      return "desktop"
  }else
  if(size >= 1140 && size < 2879){
      return "desktop"
  }else
  if(size >= 2880){
      return "big";
  }
}

const NFTView1 = ({ onClose ,nfts,paymentSanity}) => {
  
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const KEY = query.get('key');
  const _id = query.get('id');
  const [ openBuy, setOpenBuy ] = React.useState(false);
  const [ openGeneral, setOpenGeneral ] = React.useState(true);
  const [ openBenefits, setOpenBenefits ] = React.useState(false);
  const [ openMetadata, setOpenMetadata ] = React.useState(false);
  const [ nft, setNFT ] = React.useState(null);
 // const [ currentPrice, setCurrentPrice ] = React.useState({eth: 0, mxn: 0});
  const [ benefit, setBenefit ] = React.useState(null);
  const [ showWinter, setShowWinter ] = React.useState(false);
  const [ currentPhase, setCurrentPhase ] = React.useState(PHASES.selectPaymentMethod);
  const { collection } = useContext(CollectionContext);
  console.log(collection)
  const { currentPrice } = useContext(PriceContext);
  const [error, setError] = React.useState(null);
  let width = window.innerWidth;
  const [typeDevice,setTypeDevice] = React.useState(handleResize(width));

  const handleOpenGeneral = () => {
    setOpenGeneral(true);
    setOpenBenefits(false);
    setOpenMetadata(false)
  }

  const handlePaymentError = (error) => {
    console.log(error)
    setCurrentPhase(PHASES.selectPaymentMethod);
    setError(error)
  }

  const handleOpenBenefits = () => {
    setOpenBenefits(true);
    setOpenGeneral(false);
    setOpenMetadata(false)
  }

  const handleMetadata = () => {
    setOpenMetadata(true)
    setOpenBenefits(false);
    setOpenGeneral(false);
  }

  const handleOpenModal = () =>{
    if(nft !== '' && nft !== null){
      setOpenBuy(true)
    }
  }
  const handleFinishTransaction = () => {
    setShowWinter(false)
    setOpenBuy(true);
    setCurrentPhase(PHASES.fillData);
  }

  const handleFinish = () => {
    setOpenBuy(true);
    setCurrentPhase(PHASES.finish);
  }

  const handleWinterCheckout = () => {
    setOpenBuy(false)
    setShowWinter(true)
  }
  React.useEffect(() => {
    if (nfts && nfts.length > 0) {
      let newArray = [...nfts];
      setNFT(newArray.find((item) => {
        return String(item.key).toUpperCase() === String(KEY).toUpperCase() && item.tokenId === Number(_id);
      }));
    }
  }, [nfts, KEY,_id]);

  const getBenefits = async () =>{
    try {
      const res = await benefits();
      setBenefit(res);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getBenefits()
  },[]);

  React.useEffect(() => {
    window.addEventListener("resize", function(){
        width = window.innerWidth;
        setTypeDevice(handleResize(width));
    });

    window.removeEventListener("resize",function(){
        width = window.innerWidth;
        setTypeDevice(handleResize(width));
    });
  },[width]);


/*   const getPrice = async () =>{
    try {
      setCurrentPrice({eth: parseFloat(price.eth), mxn: parseFloat(price.mxn)});
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    if(collection) {
      getPrice()
    }
  },[collection,price]); */

  const handleCloseWinter = () => {
    setShowWinter(false);
  }

  const goOpenSea = () => {
    if(nft) {
      let blockchain = nft.blockchain.toLowerCase().includes('polygon') ? 'matic' : 'ethereum'
      let url = (nft.testnet) ? 'https://opensea.io/es/assets/' : 'https://testnet.opensea.io/es/assets/'
      window.open(`${url}${blockchain}/${nft.key}/${nft.tokenId}`, '_blank');
    }
  }
  return (
    <div className={styles.nftView}>
      <div className={styles.content}>
        <div className={styles.nft}>
          {nft && 
          <NFTCardSaleInfo
            price={ currentPrice }
            nft={ nft }/>
          }
        </div>
        <div className={styles.info1}>
          <div style={{marginBottom:'15px',width:'100%'}}>
          {nft && nft.owner.toUpperCase() === nft.creator.toUpperCase() && nft && nfts &&
            <div onClick={handleOpenModal} className={styles.contentBuy}>Comprar</div>
          }
          </div>
          <div className={styles.contentOptions}>
            <div className={styles.tabs}>
              <div onClick={handleOpenGeneral} className={openGeneral ? `${styles.tab} ${styles.tabActive} ` : `${styles.tab}` }>
                <div className={styles.general}>General</div>
              </div>
              <div onClick={handleOpenBenefits} className={openBenefits ? `${styles.tab} ${styles.tabActive} ` : `${styles.tab}` }>
                <div className={styles.general}>Beneficios</div>
              </div>
              <div onClick={handleMetadata} className={openMetadata ? `${styles.tab} ${styles.tabActive} ` : `${styles.tab}` }>
                <div className={styles.general}>Metadatos</div>
              </div>
            </div>
            <div className={styles.globalPanel}>
              <div  className={openGeneral  ? `${styles.panelGeneral} ${styles.panelGeneralActive}` : `${styles.panelGeneral}`}>
                <div className={openGeneral ? `${styles.bodyGeneral} ${styles.bodyGeneralActive}` : `${styles.bodyGeneral}`}>
                <NFTInfoGeneral nft={nft} />
                </div>
              </div>
              <div  className={openBenefits  ? `${styles.panelGeneral} ${styles.panelGeneralActive}` : `${styles.panelGeneral}`}>
                <div className={openBenefits ? `${styles.bodyGeneral} ${styles.bodyGeneralActive}` : `${styles.bodyGeneral}`}>
                  <div className={styles.globalContentBenefits}>
                    <div className={styles.textBenefits}>
                      <b className={styles.losBeneficiosSern}>
                          {benefit && benefit.title && benefit.title}
                      </b>
                      {
                        benefit && benefit.description && 
                        <BlockContent  blocks={ benefit.description } className={styles.loremIpsumDolor} />
                      }
                    </div>
                    <div className={styles.contentBenefits}> 
                      <div className={styles.beneficios} style={{marginBottom:'15px'}}>
                        { benefit && benefit.benefits && benefit.benefits.length > 0 &&
                        <CarouselCustomViewNFT 
                          items={benefit.benefits}
                          typeDevice={typeDevice} 
                        />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div  className={openMetadata  ? `${styles.panelGeneral} ${styles.panelGeneralActive}` : `${styles.panelGeneral}`}>
                <div className={openMetadata ? `${styles.bodyGeneral} ${styles.bodyGeneralActive}` : `${styles.bodyGeneral}`}>
                  <div className={styles.coleccionable}>
                    <div className={styles.metadatos}>
                      <div className={styles.listaAtributos}>

                        <div className={styles.atributos1} style={{display:'grid',gridTemplateColumns:'1fr 1fr'}}>
                          {
                            nft && nft.attributes && nft.attributes.length > 0 &&
                            nft.attributes?.map((item,index)=>{
                              return(
                                <Attribute key={index}   item={item} />
                              )
                            })
                          }
                        </div>
                        <div className={styles.atributos12}>
                          <ParticleVertical nft={nft} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.botonesNft} onClick={() => {goOpenSea()}}>
              <div className={styles.share}>
                <div className={styles.v982D3041Wrapper}>
                  <img
                    className={styles.v982D3041Icon}
                    alt=""
                    src="/v982d304-11@2x.png"
                  />
                </div>
                <div className={styles.verEnOpensea}>Ver en OpenSea</div>
              </div>
            </div>
          </div>
          <div onClick={()=> navigate("/home")} className={styles.contentBack}>Volver al inicio</div>
        </div>
      </div>
      { error &&
          <Error
            message = {error.message}
            error={error.status}
            title = { error.title}/>
      }
      {
        nft != null && nft.thumb_url && nfts && currentPrice ?
        <ModalBuy
          open={openBuy}
          setOpen={setOpenBuy}
          nft={nft}
          handleWinterCheckout={handleWinterCheckout}
          handleFinishTransaction={handleFinishTransaction}
          handlePaymentError={(error) => handlePaymentError(error)}
          handleFinalPhase={() => handleFinish()}
          currentPhase={currentPhase}
          currentPrice={currentPrice}
          allNFTs={nfts}
          paymentSanity={paymentSanity}
        />
        :null
      }
        {nft != null && nft.thumb_url &&
          <WinterCheckout
          projectId={10227}
          production={true}
          showModal={showWinter}
          extraMintParams={{ quantity : 1, tokenId: nft.tokenId }}
          onSuccess={() => handleFinishTransaction()}
          onClose={() => handleCloseWinter()}
           />

        }
    </div>
  );
};

export default NFTView1;
